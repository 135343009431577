import MD5 from "crypto-js/md5";

export const initializeDataLayer = (
  orderData,
  userToken,
  apiVersion2 = false
) => {

  if(!orderData) return;

  const unix_stamp = Math.floor(Date.now() / 1000);
  const email = apiVersion2 ? orderData?.email : orderData?.customer.email;
  const first_name = apiVersion2
    ? orderData?.shipping_address?.first_name
    : orderData?.customer.first_name;
  const last_name = apiVersion2
    ? orderData?.shipping_address?.last_name
    : orderData?.customer.last_name;
  const cart_total_with_discounts = apiVersion2
    ? orderData?.pricing?.discounted_subtotal
    : orderData?.cart_total_with_discounts;
  const phone_number = apiVersion2
    ? orderData?.phone_number
    : orderData?.customer.phone_number;
  const sms_marketing_consent_checked = apiVersion2
    ? orderData?.sms_marketing_consent_checked
    : orderData?.consent_boolean;
  const checkout_session_id = apiVersion2
    ? orderData?.checkout_session_id
    : orderData?.order_id;
  const discount_codes = apiVersion2
    ? orderData?.discount_codes
    : orderData?.discounts;
  const productsTracking = apiVersion2
    ? JSON.parse(orderData?.tracking_parameters?.products || "[]")
    : [];
  const offerTracking = apiVersion2
    ? orderData?.tracking_parameters?.offer
    : null;
  let hash = MD5(
    `${email},${first_name},${cart_total_with_discounts},${unix_stamp},20f1c93a0fd7915399e203e6eb60555a`
  );

  //CAA-293 - FriendBuy Discount Codes
  let friendBuyDiscounts = "";
  let isFriendBuy = false;
  const regex = /^FRND/i;

  if (orderData?.discounts) {
    for (const d of orderData?.discounts) {
      if (regex.test(d)) {
        friendBuyDiscounts = d;
        isFriendBuy = true;
      }
    }
  }

  //PPP-174 - FriendBuy Product Array
  let friendBuyProducts = [];

  if (isFriendBuy) {
    for (let i in orderData?.items) {
      const buildObj = {};
      buildObj["name"] = orderData?.products[i].product_name;
      buildObj["sku"] = orderData?.products[i].SKU;
      buildObj["price"] = orderData?.items[i].price;
      buildObj["quantity"] = orderData?.items[i].quantity;
      friendBuyProducts.push(buildObj);

      i++;
    }
  }

  //PPP-198 - Check if purchase includes SKU like "AEASE"
  let isAEASE = false;
  const aeaseREGEX = /AEASE/;

  for (let i in orderData?.items) {
    if (aeaseREGEX.test(orderData?.products[i].SKU)) {
      isAEASE = true;
    }
    i++;
  }

  const products = apiVersion2 ? orderData?.line_items : orderData?.items;

  const productItemTransformer = (item, index) => ({
    product_id: `${item.product_id}`,
    product_name: item.product_name,
    product_image_src: item.image_url,
    core_sku: productsTracking?.at(index)?.core_sku || null,
    sku: item.sku || item.SKU,
    product_category: productsTracking?.at(index)?.product_category || null,
    product_sub_category:
      productsTracking?.at(index)?.product_sub_category || null,
    price: item.pricing.line_item_price,
    discount_price: item.pricing.discounted_line_item_price,
    target_animal: productsTracking?.at(index)?.target_animal || null,
    size: productsTracking?.at(index)?.size || null,
    units: item.quantity,
    weight: productsTracking?.at(index)?.weight || null,
    sub_or_otp: item.schedule ? "sub" : "otp",
    schedule: item.schedule,
    offer_flag: !!offerTracking,
    free_gift_flag: item.pricing.discounted_line_item_price === 0,
    offer: offerTracking
  });

  const productsObject = products.map((item, index) =>
    apiVersion2
      ? productItemTransformer(item, index)
      : productItemTransformer(
          {
            product_id: `${item.product_id}`,
            product_name: item.product_name,
            image_url: item.product_image,
            pricing: {
              line_item_price: item.perceived_rrp,
              discounted_line_item_price: item.discounted_price
            },
            quantity: item.quantity,
            schedule: item.sub_frequency || null,
            ...orderData?.products[index]
          },
          index
        )
  );

  const totalQuantity = products.reduce((acc, item) => acc + item.quantity, 0);
  const transactionTransformer = orderData => ({
    checkout_session_id: orderData.checkout_session_id,
    total_items: totalQuantity,
    total_value: orderData.pricing.order_total,
    currency: orderData.currency.code,
    payment_method: orderData.transaction?.gateway,
    tax: orderData.pricing.taxes,
    shipping:
      orderData.pricing.discounted_subtotal_with_shipping -
      orderData.pricing.discounted_subtotal,
    discount_code: orderData.discount_codes_detail[0].discount_code,
    discount_amount: orderData.pricing.total_savings,
    credit_order: false
  });

  const transactionObject = apiVersion2
    ? transactionTransformer(orderData)
    : transactionTransformer({
        checkout_session_id: userToken,
        currency: orderData.currency,
        transaction: {
          gateway: null
        },
        discount_codes: orderData.discounts,
        discount_codes_detail: orderData.discounts.map(discount => ({
          discount_code: discount
        })),
        pricing: {
          discounted_subtotal_with_shipping:
            orderData.cart_total_with_discounts + orderData.shipping,
          discounted_subtotal: orderData.cart_total_with_discounts,
          taxes: orderData.tax,
          total_savings: orderData.checkout_discount_amount,
          subtotal: orderData.checkout_total,
          order_total: orderData.checkout_total
        }
      });

  const data = {
    event: "transaction",
    ecommerce: {
      purchase: {
        orderid: checkout_session_id,
        revenue: cart_total_with_discounts
      },
      basket: {
        products: orderData?.products
      },
      text_consent: {
        consent_boolean: sms_marketing_consent_checked,
        phone_number: phone_number
      },
      customer_email: email,
      first_name: first_name,
      last_name: last_name,
      unix_timestamp: unix_stamp,
      md5_hash: hash.toString(),
      discount_codes: discount_codes,
      friend_buy_discount_code: friendBuyDiscounts,
      friendbuy_products: friendBuyProducts,
      contains_aease: isAEASE,
      checkout_session_id: userToken
    },
    products: productsObject,
    transaction: transactionObject
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
